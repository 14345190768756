<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
    <b-table
      :items="tableData"
      responsive
      :fields="fields"
      class="mb-0"
    >
      <template #cell(order_count)="data">
        {{ (data.value).toLocaleString() }}
      </template>
      <template #cell(delivery_charge)="data">
        {{ __numberWithCommas(data.value, true) }}
      </template>
      <template #cell(ratio)="data">
        {{ roundToNearest(data.value) }}
      </template>
      <!-- statusIcon -->
      <template #cell(status_icon)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            size="32"
            :variant="'light-'+data.item.status_color"
          >
            <feather-icon
              size="20"
              :icon="data.value"
            />
          </b-avatar>
          <div />
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      avatarText,
      fields: [
        {
          key: 'status_icon',
          label: 'Status Icon',
        },
        {
          key: 'status_name',
          label: 'Status Name',
        },
        {
          key: 'order_count',
          label: 'No of Orders',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'delivery_charge',
          label: 'Delivery Charge',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'ratio',
          label: 'Ratio %',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
    }
  },
  methods: {
    roundToNearest(ratio) {
      return `${Number(ratio).toFixed(0)}%`
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../@core/scss/base/bootstrap-extended/include';
@import '../../@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
