<template>
  <b-card
    v-if="data"
    no-body
    class="card-revenue-budget"
  >
    <b-row class="mx-0">
      <b-col
        md="12"
        class="revenue-report-wrapper"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0 chart-title">
            COD Income
          </h4>
          <feather-icon
            v-b-tooltip.hover.v-primary="'This chart presents data from the past 12 months, providing an up-to-date snapshot of current trends and patterns.'"
            icon="HelpCircleIcon"
            size="15"
            class="cursor-pointer text-primary"
          />
        </div>

        <!-- chart -->
        <vue-apex-charts
          id="revenue-report-chart"
          height="230"
          :options="chartOptions"
          :series="data.series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, VBTooltip,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      revenue_report: {},
      chartOptions: {
        chart: {
          type: 'line',
          toolbar: { show: false },
          zoom: { enabled: true },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [5, 7, 5],
          curve: 'straight',
          dashArray: [0, 8, 5],
        },
        title: {
          text: '',
          align: 'left',
        },
        legend: {
          tooltipHoverFormatter(val, opts) {
            return `${val} - ${opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]}`
          },
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        xaxis: {
          categories: this.data.categories.lables,
        },
        yaxis: {
          labels: {
            formatter(value) {
              return value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            },
            style: {
              colors: '#6E6B7B',
              fontSize: '0.86rem',
              fontFamily: 'Montserrat',
            },
          },
        },
        grid: {
          borderColor: '#f1f1f1',
        },
        tooltip: {
          y: [
            {
              title: {
                formatter(val) {
                  return `${val} (Rs)`
                },
              },
            },
            {
              title: {
                formatter(val) {
                  return `${val} per session`
                },
              },
            },
            {
              title: {
                formatter(val) {
                  return val
                },
              },
            },
          ],
        },
      },
    }
  },
}
</script>
