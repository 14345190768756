<template>
  <b-card
    v-if="data"
    md="6"
    sm="6"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title class="chart-title">Financial Statistics</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0" />
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in data"
          :key="item.icon"
          :class="['col-12', 'col-md-3', 'mt-md-0', 'mt-1', item.customClass]"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2 smaller-size"
            >
              <b-avatar
                size="40"
                :variant="item.color"
                class="smaller-size"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4
                v-b-tooltip.hover.v-primary
                class="font-weight-bolder mb-0"
                :title="__numberWithCommas(item.title, true)"
              >
                {{ __numberWithCommas(kFormatter(item.title)) }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  VBTooltip,
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    kFormatter,
  },
}
</script>
<style scoped>
@media (max-width: 745px) {
  .card-statistics .col-12.col-md-6 {
    width: 100% !important;
    margin-top: 1rem;
  }
}
@media (max-width: 1024px) {
  .smaller-size {
    width: 20px !important;
    height: 20px !important;
  }
}
</style>
