<template>
  <b-card
    v-if="data"
    no-body
    class="card-revenue-budget"
  >
    <b-row class="mx-0">
      <b-col
        md="12"
        class="revenue-report-wrapper"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0 chart-title">
            {{ title }}
          </h4>
        </div>

        <!-- chart -->
        <vue-apex-charts
          id="revenue-report-chart"
          height="230"
          :options="chartOptions"
          :series="data.series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      revenue_report: {},
      chartOptions: {
        chart: {
          type: this.type,
          toolbar: { show: false },
          zoom: { enabled: true },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [5, 7, 5],
          curve: 'straight',
          dashArray: [],
        },
        title: {
          text: '',
          align: 'left',
        },
        legend: {
          tooltipHoverFormatter(val, opts) {
            return `${val} - ${opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]}`
          },
          offsetY: 5,
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        responsive: [
          {
            breakpoint: 1780,
            options: {
              xaxis: {
                categories: this.data.categories.lables,
                labels: {
                  show: true,
                  rotate: -45,
                  rotateAlways: true,
                },
              },
            },
          },
          {
            breakpoint: 767,
            options: {
              xaxis: {
                categories: this.data.categories.lables,
                labels: {
                  show: true,
                  rotate: -45,
                  rotateAlways: true,
                },
              },
              tooltip: {
                fixed: {
                  enabled: true,
                  position: 'topRight',
                },
              },
            },
          },
        ],
        xaxis: {
          categories: this.data.categories.lables,
          labels: {
            show: true,
            rotate: 0,
            rotateAlways: false,
            hideOverlappingLabels: false,
            showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: '11px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 700,
              cssClass: 'apexcharts-xaxis-label',
            },
          },
        },
        yaxis: {
          labels: {
            formatter(value) {
              return value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            },
            style: {
              colors: '#6E6B7B',
              fontSize: '0.86rem',
              fontFamily: 'Montserrat',
            },
          },
        },
        grid: {
          borderColor: '#f1f1f1',
        },
        tooltip: {
          y: [
            {
              title: {
                formatter(val) {
                  return `${val} (Days)`
                },
              },
            },
            {
              title: {
                formatter(val) {
                  return `${val} per session`
                },
              },
            },
            {
              title: {
                formatter(val) {
                  return val
                },
              },
            },
          ],
        },
      },
    }
  },
}
</script>
