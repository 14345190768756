<template>
  <section id="dashboard-ecommerce">

    <b-row class-name="match-height">
      <b-col
        md="4"
        class-name="align-content-center"
      >
        <ecommerce-statistics :data="tempdata.statisticsItems" />
      </b-col>
      <b-col
        md="8"
        class-name="align-content-center"
      >
        <ecommerce-financial-statistics :data="tempdata.financialstatisticsItems" />
      </b-col>
    </b-row>

    <b-row class-name="match-height">
      <b-col
        md="6"
      >
        <div v-if="!isMonthlyEarningsLoading">
          <ecommerce-monthly-earnings-chart :data="tempdata.monthlyEarnings" />
        </div>
      </b-col>
      <b-col
        md="6"
      >
        <div v-if="!isBarChartLoading">
          <ecommerce-revenue-report :data="tempdata.monthlyOrders" />
        </div>
      </b-col>
    </b-row>

    <b-row class-name="match-height">
      <!--      <b-col-->
      <!--        md="3"-->
      <!--      >-->
      <!--        <div v-if="!isCODReceivableLoading">-->
      <!--          <ecommerce-earnings-chart :data="tempdata.codReceivable" />-->
      <!--        </div>-->
      <!--      </b-col>-->
      <b-col
        md="3"
      >
        <div v-if="!isToBeInvoicesLoading">
          <ecommerce-transactions :data="tempdata.toBeInvoices" />
        </div>
      </b-col>

      <!-- Revenue Report Card -->
      <b-col md="9">
        <div v-if="!isMerchantOnBoarding">
          <ecommerce-delivery-charge
            title="Monthly Delivery Charge"
            type="line"
            :data="tempdata.monthlyDeliveryCharge"
          />
        </div>
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>
    <!--<b-row class="match-height">
      <b-col
        md="12"
      >
        <div v-if="!isGroupedBarChartLoading">
          <GroupedBarChart
            :data="tempdata.chartData"
          />
        </div>
      </b-col>
    </b-row>-->
    <b-row class="match-height">
      <!-- Citywise Statistic card -->
      <b-col
        md="12"
      >
        <div v-if="!isCitywiseStatsLoading">
          <ecommerce-client-onboarded
            title="Citywise Delivery Statistics"
            type="bar"
            :data="tempdata.cityWiseStats"
          />
        </div>
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>

    <b-row class-name="match-height">
      <!-- Company Table Card -->
      <b-col lg="12">
        <div v-if="!isMStatusStatistics">
          <ecommerce-company-table :table-data="tempdata.companyTable.data" />
        </div>
      </b-col>
      <!--/ Company Table Card -->
    </b-row>

    <b-row class-name="match-height">
      <!-- Company Table Card -->
      <!--      <b-col lg="12">-->
      <!--        <ecommerce-last-few-monthly-earning :data="tempdata.monthlyEarnings" />-->
      <!--      </b-col>-->
      <!--/ Company Table Card -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
// import GroupedBarChart from '@/components/Dashboard/GroupedBarChart.vue'
import EcommerceStatistics from '@/components/Dashboard/EcommerceStatistics.vue'
import EcommerceTransactions from '@/components/Dashboard/EcommerceTransactions.vue'
import EcommerceFinancialStatistics from '@/components/Dashboard/EcommerceFinancialStatistics.vue'
import EcommerceRevenueReport from '@/components/Dashboard/EcommerceRevenueReport.vue'
// import EcommerceEarningsChart from '@/components/Dashboard/EcommerceEarningsChart.vue'
import EcommerceMonthlyEarningsChart from '@/components/Dashboard/EcommerceMonthlyEarning.vue'
// import EcommerceLastFewMonthlyEarning from '@/components/Dashboard/EcommerceLastFewMonthlyEarning.vue'
import EcommerceCompanyTable from '@/components/Dashboard/EcommerceCompanyTable.vue'
import EcommerceClientOnboarded from '@/components/Dashboard/EcommerceClientOnboarded.vue'
import EcommerceDeliveryCharge from '@/components/Dashboard/EcommerceDeliveryCharge.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const DashboardRepository = RepositoryFactory.get('dashboard')
// eslint-disable-next-line no-unused-vars
const BusinessSettingsRepository = RepositoryFactory.get('businessSettings')

export default {
  components: {
    // GroupedBarChart,
    EcommerceClientOnboarded,
    EcommerceDeliveryCharge,
    BRow,
    BCol,
    EcommerceTransactions,
    // EcommerceLastFewMonthlyEarning,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceFinancialStatistics,
    EcommerceMonthlyEarningsChart,
    // EcommerceEarningsChart,
    EcommerceCompanyTable,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      businessProfileCurrency: null,
      data: {},
      lables: [],
      isBarChartLoading: false,
      isMonthlyEarningsLoading: false,
      isCODReceivableLoading: false,
      isToBeInvoicesLoading: false,
      isMerchantOnBoarding: false,
      isMStatusStatistics: false,
      isCitywiseStatsLoading: false,
      // isGroupedBarChartLoading: false,
      tempdata: {
        // chartData: {
        //   series: [
        //     {
        //       name: 'Forecasted',
        //       data: [80, 100, 60, 40],
        //     },
        //     {
        //       name: 'Actual Success',
        //       data: [70, 60, 40, 10],
        //     },
        //     {
        //       name: 'Actual Fail',
        //       data: [10, 40, 20, 30],
        //     },
        //   ],
        // },
        statisticsItems: [
          {
            icon: 'BoxIcon',
            customClass: '',
            color: 'light-warning',
            title: 0,
            subtitle: 'Orders Count',
          },
          {
            icon: 'DollarSignIcon',
            customClass: 'ml-3',
            color: 'light-success',
            title: 0,
            subtitle: 'Delivery Charge',
          },
        ],
        financialstatisticsItems: [
          {
            icon: 'ShoppingBagIcon',
            customClass: '',
            color: 'light-info',
            title: 0,
            subtitle: 'Invoice Value',
          },
          {
            icon: 'LoaderIcon',
            customClass: '',
            color: 'light-warning',
            title: 0,
            subtitle: 'Pending Invoice',
          },
          {
            icon: 'CheckCircleIcon',
            customClass: '',
            color: 'light-success',
            title: 0,
            subtitle: 'Paid Invoice Value',
          },
          {
            icon: 'UserCheckIcon',
            customClass: '',
            color: 'light-primary',
            title: 0,
            subtitle: 'Approved Invoice',
          },
        ],
        monthlyEarnings: {
          categories:
              { lables: [] },
          series: [
            {
              name: 'COD',
              data: [],
            },
          ],
        },
        codReceivable: {
          series: [],
        },
        toBeInvoices: [
          {
            avatarVariant: 'light-warning',
            avatar: 'CodesandboxIcon',
            mode: 'Collected COD',
            types: this.businessProfileCurrency || (localStorage.getItem('businessCurrency') === 'ALL' ? 'LKR' : localStorage.getItem('businessCurrency')),
            deduction: false,
            payment: 0,
          },
          {
            avatarVariant: 'light-success',
            avatar: 'TruckIcon',
            mode: 'Delivery Charge',
            types: this.businessProfileCurrency || (localStorage.getItem('businessCurrency') === 'ALL' ? 'LKR' : localStorage.getItem('businessCurrency')),
            deduction: false,
            payment: 0,
          },
          {
            avatarVariant: 'light-warning',
            avatar: 'PercentIcon',
            mode: 'Commission',
            types: this.businessProfileCurrency || (localStorage.getItem('businessCurrency') === 'ALL' ? 'LKR' : localStorage.getItem('businessCurrency')),
            deduction: false,
            payment: 0,
          },
          {
            avatarVariant: 'light-info',
            avatar: 'DollarSignIcon',
            mode: 'Invoice Value',
            types: this.businessProfileCurrency || (localStorage.getItem('businessCurrency') === 'ALL' ? 'LKR' : localStorage.getItem('businessCurrency')),
            deduction: false,
            payment: 0,
          },
        ],
        monthlyOrders: {
          categories:
              { lables: [] },
          series: [
            { data: [] },
          ],
        },
        monthlyDeliveryCharge: {
          categories:
              { lables: [] },
          series: [
            {
              name: 'Delivery Expenses',
              data: [],
              dashArray: [],
            },
            {
              name: 'Partially Delivered',
              data: [],
              dashArray: [],
            },
            {
              name: 'Return Expense',
              data: [],
            },
          ],
        },
        cityWiseStats: {
          categories:
              { lables: [] },
          series: [
            {
              name: 'Delivered orders',
              data: [],
            },
          ],
        },
        companyTable: {
          data: [],
        },
      },
    }
  },
  mounted() {
    // Set the initial number of items
    this.readDataforOrderStats()
    this.readDataforFinancialStats()
    this.readDataforMonthlyOrders()
    this.readDataforMonthlyEarnings()
    this.readDataforCODReceivable()
    this.readDataforToBeInvoices()
    this.readDataforMonthlyDeliveryCharge()
    this.readDataforStatusStatistics()
    this.readDataforCitywiseStats()
    // this.readDataForGroupedBarChart()
  },
  created() {
    this.getBusinessCurrency()
  },
  methods: {
    currentDate() {
      const current = new Date()
      return `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
    },
    // async readDataForGroupedBarChart() {
    //   this.isGroupedBarChart = true
    //   try {
    //     const { data } = await DashboardRepository.getGroupedBarChart()
    //     this.tempdata.chartData.categories.labels = data.map(n => n.label)
    //     this.tempdata.chartData.series[0].data = data.map(n => n.value)
    //   } catch (e) {
    //     this.convertAndNotifyError(e)
    //   }
    //   this.isGroupedBarChart = false
    // },
    async getBusinessCurrency() {
      try {
        // eslint-disable-next-line no-unused-vars
        const { data: { company_currency } } = (await BusinessSettingsRepository.getBusinessSettigs()).data
        // eslint-disable-next-line camelcase
        this.businessProfileCurrency = company_currency
        localStorage.setItem('businessCurrency', company_currency)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforOrderStats() {
      try {
        const { data } = (await DashboardRepository.getOrderStatsFinance()).data
        this.tempdata.statisticsItems[0].title = data.order_count
        this.tempdata.statisticsItems[1].title = data.total_delivery_charge
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforFinancialStats() {
      try {
        const { data } = (await DashboardRepository.getFinancialStatFinance(this.id)).data
        this.tempdata.financialstatisticsItems[0].title = data.total_invoice_amount
        this.tempdata.financialstatisticsItems[1].title = data.status_amount.filter(item => item.status_key === 'f_key_1').length > 0 ? data.status_amount.filter(item => item.status_key === 'f_key_1')[0].amount : 0
        this.tempdata.financialstatisticsItems[2].title = data.status_amount.filter(item => item.status_key === 'f_key_2').length > 0 ? data.status_amount.filter(item => item.status_key === 'f_key_2')[0].amount : 0
        this.tempdata.financialstatisticsItems[3].title = data.status_amount.filter(item => item.status_key === 'f_key_3').length > 0 ? data.status_amount.filter(item => item.status_key === 'f_key_3')[0].amount : 0
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforMonthlyOrders() {
      this.isBarChartLoading = true
      try {
        const { data } = (await DashboardRepository.getMonthlyOrdersFinance()).data
        this.tempdata.monthlyOrders.categories.lables = data.map(n => (`${n.month} ${n.year}`)).reverse().slice(-12)
        this.tempdata.monthlyOrders.series[0].data = data.map(n => n.data).reverse().slice(-12)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.isBarChartLoading = false
    },
    async readDataforMonthlyEarnings() {
      this.isMonthlyEarningsLoading = true
      try {
        const { data } = (await DashboardRepository.getMonthlyEarningsFinance()).data
        this.tempdata.monthlyEarnings.categories.lables = data.map(n => (`${n.month} ${n.year}`)).reverse().slice(-12)
        this.tempdata.monthlyEarnings.series[0].data = data.map(n => n.monthly_cod_income).reverse().slice(-12)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.isMonthlyEarningsLoading = false
    },
    async readDataforCODReceivable() {
      this.isCODReceivableLoading = true
      try {
        const { data } = (await DashboardRepository.getCODRecivableFinance()).data
        this.tempdata.codReceivable.series[0] = data.total_receivable_cod
        this.tempdata.codReceivable.series[1] = data.agency_receivable_commission
        this.tempdata.codReceivable.series[2] = data.branch_receivable_cod
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.isCODReceivableLoading = false
    },
    async readDataforToBeInvoices() {
      this.isToBeInvoicesLoading = true
      try {
        const { data } = (await DashboardRepository.getToBEInvoicesFinance()).data
        if (data[0].collected_cod === null) {
          this.tempdata.toBeInvoices[0].payment = 0
        } else {
          this.tempdata.toBeInvoices[0].payment = data[0].collected_cod
        }
        if (data[0].delivery_charge === null) {
          this.tempdata.toBeInvoices[1].payment = 0
        } else {
          this.tempdata.toBeInvoices[1].payment = data[0].delivery_charge
        }
        if (data[0].commision === null) {
          this.tempdata.toBeInvoices[2].payment = 0
        } else {
          this.tempdata.toBeInvoices[2].payment = data[0].commision
        }
        if (data[0].to_be_invoice_amount === null) {
          this.tempdata.toBeInvoices[3].payment = 0
        } else {
          this.tempdata.toBeInvoices[3].payment = data[0].to_be_invoice_amount
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.isToBeInvoicesLoading = false
    },
    async readDataforMonthlyDeliveryCharge() {
      this.isMerchantOnBoarding = true
      try {
        const { data } = (await DashboardRepository.getmonthlyDeliveryChargeFinance()).data
        this.lables = Object.keys(data)
        this.tempdata.monthlyDeliveryCharge.categories.lables = (this.lables).reverse().slice(-12)
        if (data) {
          this.tempdata.monthlyDeliveryCharge.series[0].data = (Object.values(data).map(item => item.delivery)).reverse().slice(-12)
          this.tempdata.monthlyDeliveryCharge.series[1].data = (Object.values(data).map(item => item.partially)).reverse().slice(-12)
          this.tempdata.monthlyDeliveryCharge.series[2].data = (Object.values(data).map(item => item.return)).reverse().slice(-12)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.isMerchantOnBoarding = false
    },
    async readDataforStatusStatistics() {
      this.isMStatusStatistics = true
      try {
        const { data } = (await DashboardRepository.getStatusStatisticsFinance()).data
        this.tempdata.companyTable.data = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.isMStatusStatistics = false
    },
    async readDataforCitywiseStats() {
      this.isCitywiseStatsLoading = true
      try {
        const { data } = (await DashboardRepository.getCityWiseStats()).data
        this.tempdata.cityWiseStats.categories.lables = data.map(n => (`${n.city_name} (${n.warehouse_name})`))
        this.tempdata.cityWiseStats.series[0].data = data.map(n => n.count)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.isCitywiseStatsLoading = false
    },
  },
}
</script>

<style lang="scss">
@import '../@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '../@core/scss/vue/libs/chart-apex.scss';
</style>
